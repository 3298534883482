
import { NavigationTabs, SearchTabs } from '@/components/freetools'
import { Component, Vue } from 'vue-property-decorator'
import { Article, ArticlePageBibInfo } from '@/components/article-page'
import { FreeToolsAPI } from '@/api/freetools-api'
import { ArticlesAPI } from '@/api'

@Component({
  components: {
    NavigationTabs,
    ArticlePageBibInfo,
  },
})
export default class MRef extends Vue {
  //
  // REACTIVE PROPERTIES
  //
  selectedTab = SearchTabs.mRef
  ref = ''
  article: Article | null = null
  notFound = false
  loading = false
  format = 'citation'
  formatOptions = [
    {
      text: 'MathSciNet',
      value: 'citation',
    },
    {
      text: 'Link',
      value: 'link',
    },
    {
      text: 'BibTeX',
      value: 'bibtex',
    },
    {
      text: 'AMSRefs',
      value: 'amsrefs',
    },
    {
      text: 'TeX',
      value: 'tex',
    },
    {
      text: 'EndNote',
      value: 'endnote',
    },
  ]

  //
  // COMPUTED PROPERTIES
  //
  get isSubmittable() {
    return this.ref.trim().length > 0
  }

  //
  // METHODS
  //
  mounted() {
    document.title = 'MRef - MathSciNet'
    const urlParams = new URLSearchParams(window.location.search)
    const ref = urlParams.get('ref') || ''
    if (ref.length > 0) {
      this.ref = ref
      // const textEntry = this.$refs['mref-text'] as HTMLTextAreaElement
      // textEntry.value = ref
    }
  }

  async onSearch(e: Event) {
    e.preventDefault()
    const urlParams = new URLSearchParams(window.location.search)
    urlParams.set('ref', this.ref)
    window.history.replaceState({}, document.title, '?' + urlParams.toString())

    this.loading = true
    this.notFound = false
    this.article = null
    const mref = await FreeToolsAPI.getMRef(this.ref)
    if (mref.paperId) {
      this.article = await ArticlesAPI.getArticlePublicWithFormats(mref.paperId)
    } else {
      this.notFound = true
    }
    this.loading = false
  }

  onClear(e: Event) {
    e.preventDefault()
    this.ref = ''
    this.article = null
    this.notFound = false
  }

  formattedMRNumber(mrnumber: number) {
    // Add leading zeros if needed
    const mrNumLength = 7
    const leadingZeros = mrNumLength - mrnumber.toString().length
    return '0'.repeat(leadingZeros) + mrnumber
  }
}

